import type { ContentTypeCommunityStoryblok } from '@/types/storyblok'

export function getDirectionsLink(
  communityContent: ContentTypeCommunityStoryblok,
): string {
  const {
    mapLocationLatitude,
    mapLocationLongitude,
    displaySuiteLocation,
    googleMapsDirectionsUrl,
  } = communityContent
  const lat = displaySuiteLocation?.[0]?.latitude || mapLocationLatitude
  const lng = displaySuiteLocation?.[0]?.longitude || mapLocationLongitude

  return googleMapsDirectionsUrl
    ? googleMapsDirectionsUrl
    : `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
}
